import { button_en } from './button'

const brand = {
  ID: 'Brand ID',
  NAME: 'Brand Name',
  ACTIVE: 'Active',
  APPROVED: 'Approved',
  BRAND_LOGO: 'Brand logo',
  BRAND_NAME: 'Brand name',
  ADD: 'Add brand',
  SAME_BRAND_WARNING: 'A brand with the same brand already exists',
  UPLOAD_LOGO: 'Please upload an image',
}
const campaign = {
  SHIFT_CONFIRMATION_DATE_TO_LAST_MONTH:
    'Shift confirmation date to last month',
  CONFIRME_REWARD: 'Confirm reward',
  NO_REWARD_FOUND: 'No reward found',
  NO_BANK_ACCOUNT: 'No bank account',
  NO_REWARD_PRICE: 'No reward price',
  NO_SETTING: 'No setting',
  REWARD_PRICE: 'Reward price',
  SELECTED: 'Selected',
  CONFIRM_REWARD_WARNING: (count: number) =>
    `No reward setting of ${count} user(s)`,
  SEND_DM_ALL_HEADER: 'Send DM to all',
  SEND_DM_DEALS_HEADER: 'Send DM to deals',
  CONFIRM_REWARDS_HEADER: 'Confirm rewards',
  SEND_DEAL_EMAIL_HEADER: 'Send deal email',
  SEND_DEAL_EMAIL_BODY: 'Are you sure you want to send a deal email?',
  EXPORT_ALL_HEADER: 'Export all',
  EXPORT_ALL_BODY: 'Are you sure you want to export all applicants?',
  EXPORT_SELECTED_HEADER: 'Export selected',
  EXPORT_SELECTED_BODY: 'Are you sure you want to export selected applicants?',
  EXPORT_DEALS_HEADER: 'Export deals',
  EXPORT_DEALS_BODY: 'Are you sure you want to export deal applicants?',
  CLOSE_PRETASK_HEADER: 'Close pretask submission',
  CLOSE_PRETASK_BODY: 'Are you sure you want to close pretask submission?',
  CREATE_SHARED_URL_HEADER: 'Create shared URL',
  CREATE_SHARED_URL_BODY: 'Are you sure you want to create shared URL?',
  INCLUDE_BLACKLISTED: 'Include blacklisted',
}
const client = {}
const influencer = {}
const product = {}
const warehouse = {}
const ugc = {
  STORE_MANAGEMENT: 'Store management',
  STORE_LIST: 'Store list',
  STORE_DETAIL: 'Store detail',
  BRAND_FILTER_LABEL: 'Filter by brand name',
  BRAND_FILTER_PLACEHOLDER: 'Select brand name',
  CREATE: 'Create',
  STORE_NAME: 'Store name',
  STORE_NAME_PLACEHOLDER: 'Enter store name',
  STORE_NAME_VALIDATION: 'Please enter a store name',
  STORE_NAME_DUPLICATED_VALIDATION: 'A store with the same name already exists',
  BRAND: 'Brand',
  BRAND_PLACEHOLDER: 'Select brand',
  BRAND_VALIDATION: 'Please select a brand',
  DOMAIN: 'Domain',
  DOMAIN_PLACEHOLDER: 'e.g. www.example.com',
  DOMAIN_VALIDATION: 'Please enter a valid domain',
  WEBSITE_ENVIRONMENT: 'Website environment',
  CART_URL_PATH: 'Cart URL path',
  CART_URL_PATH_PLACEHOLDER: 'e.g. /cart',
  THANKS_URL_PATH: 'Thanks URL path',
  THANKS_URL_PATH_PLACEHOLDER: 'e.g. /thank-you',
  URL_PATH_VALIDATION: 'Please start with "/"',
  WEBSITE_ID: 'Website ID',
  STORE_CREATED_AT: 'Created at',
  STORE_FLYOUT_TITLE: 'Create UGC Store',
  COMFIRMATION_TITLE: 'Confirmation',
  STORE_COMFIRMATION_DESCRIPTION:
    'Are you sure you want to create a new store?',
  SUCCESSFULLY_STORE_CREATED: 'Successfully created store',
  SUCCESSFULLY_STORE_CART_URL_PATH_UPDATED:
    'Successfully cart URL path updated',
  SUCCESSFULLY_STORE_THANKS_URL_PATH_UPDATED:
    'Successfully thanks URL path updated',
  NO_SETTING: 'No setting',
  LEAVE_PAGE_CONFIRMATION: 'Are you sure you want to leave this page?',
  BACK_TO_LIST: 'Back to list',
  BACK_TO_SET_LIST: 'Back to set list',
  BACK_TO_STEP_1: 'Back to step1',
  BACK_TO_STEP_2: 'Back to step2',
  LIST_PROGRESS_STEP_1_TITLE:
    'Step1. Select posts from KOLs who operate and manage the Channel.',
  LIST_PROGRESS_STEP_1_DESC:
    'Step1. Select posts to be displayed in the UGC list',
  LIST_PROGRESS_STEP_2_TITLE:
    'Step2. Add list name and link the list with your brand',
  LIST_PROGRESS_STEP_2_DESC:
    'Step2. Add list name and link the list with your brand',
  LIST_DETAILS: 'List details',
  SEARCH_POSTS: 'Search posts',
  SEARCH_BY_USERNAMES: 'Usernames',
  SEARCH_BY_HASHTAGS: 'Hashtags',
  SAVE_POST_LIST: 'Save post list',
  LIST_COMFIRMATION_DESCRIPTION: 'Are you sure you want to create a new list?',
  SUCCESSFULLY_LIST_CREATED: 'Successfully created list',
  SELECTED_POSTS: (count: number) => `Selected: ${count} item(s)`,
  POST_LIST_TITLE: 'IG post list',
  POST_LIST_LAYOUT: 'Post list layout',
  POST_LIST_LAYOUT_CAROUSEL: 'Carousel',
  POST_LIST_LAYOUT_CHILDREN: 'Children',
  SAVE_AND_COMPLETE: 'Save list',
  LIST_DESCRIPTION:
    'This list allows you to manage the posts that are scheduled to appear in the UGC panel.',
  POST_LIST: 'Post list',
  POST_LIST_PLACEHOLDER: 'Select post list',
  CREATE_POST_LIST: 'Create post list',
  PERMISSION_ALERT_TITLE: 'No permission',
  LIST_PERMISSION_ALERT_CONTENT: (role: string) =>
    `${role} permission is not allowed to create a UGC post list.`,
  ENVIRONMENT_TEST: 'Test',
  ENVIRONMENT_PRODUCTION: 'Production',
  ENVIRONMENT_DEMO: 'Demo',
  ENVIRONMENT_STAGING: 'Staging',
  ENVIRONMENT_DEVELOPMENT: 'Development',
  SET_PERMISSION_ALERT_CONTENT: (role: string) =>
    `${role} permission is not allowed to create a UGC set.`,
  SET_EDIT_PERMISSION_ALERT_CONTENT: (role: string) =>
    `${role} permission is not allowed to edit UGC set.`,
  CREATE_SET_PAGE_TITLE: 'Add UGC set',
  SHOP: 'Shop',
  SHOP_TYPE: 'Shop type',
  SHOP_TYPE_OWN_EC: 'Own EC',
  DASHBOARD: 'Dashboard',
  SET_LIST: 'UGC set list',
  SET_DESCRIPTION:
    'This set allows you to manage the UGC panel that will be displayed on your website.',
  SET_DETAIL: 'Set detail',
  SET_PANEL_SETTING: 'Panel',
  SET_LAYOUT_SETTING: 'Layout',
  SET_EDIT_PAGE: 'Edit',
  SET_ELEMENT_ID: 'Element ID',
  SET_PAGE_URL_PATH: 'Page URL path',
  SET_DISPLAY_TERM: 'Display term',
  SET_PANEL_TITLE: 'Panel title',
  SET_PANEL_TITLE_VALIDATION: 'Please enter a panel title',
  SET_PANEL_TITLE_PLACEHOLDER: 'e.g. Hot in Instagram',
  SET_PANEL_SUBTITLE: 'Panel subtitle',
  SET_PANEL_SUBTITLE_VALIDATION: 'Please enter a panel subtitle',
  SET_PANEL_SUBTITLE_PLACEHOLDER: 'e.g. Reviews',
  HIDDEN_SETTING: 'Hidden setting',
  SET_EXPECTED_LAYOUT: 'Expected layout',
  SET_EXPECTED_LAYOUT_ALERT:
    'There is no approved post, so the expected layout is not displayed.',
  SET_ITEM_COUNT: 'Item count',
  SET_ITEM_FOR_LAYOUT: (column: string, row: string) =>
    `Columns ${column} × rows ${row}`,
  SET_LAYOUT_OVERFLOW_ALERT:
    'The number of items exceeds the expected layout. The overflow will not be displayed and will be shown with the “More” button.',
  SET_COMFIRMATION_DESCRIPTION: 'Are you sure you want to create a new set?',
  SUCCESSFULLY_SET_CREATED: 'Successfully created set',
  SET_PROGRESS_STEP_1_TITLE: 'Step1. Set basic information about the UGC panel',
  SET_PROGRESS_STEP_1_DESC:
    'Step1. Enter basic information about the UGC panel',
  SET_PROGRESS_STEP_2_TITLE:
    'Step2. Configure settings related to the display order and layout of the selected list of posts and the UGC panel',
  SET_PROGRESS_STEP_2_DESC:
    'Step2. Set up the order of display and layout of the postings list',
  SET_PROGRESS_STEP_3_TITLE: 'Step3. Confirm the UGC panel settings',
  SELECT_SHOP: 'Select shop',
  SELECT_SHOP_PLACEHOLDER: 'Select shop',
  TAG_ID: 'Tag ID',
  TAG_ID_VALIDATION: 'Please enter a valid tag ID',
  TAG_ID_DUPLICATED_VALIDATION: 'A tag with the same ID already exists',
  TAG_ID_PLACEHOLDER: 'e.g. lemon-ugc-div',
  PAGE_URL_PATH: 'Page URL path',
  PAGE_URL_PATH_PLACEHOLDER: 'e.g. /products/1234',
  PAGE_URL_PATH_HELP_TEXT_1:
    'Please enter only “/” to display on the TOP page.',
  PAGE_URL_PATH_HELP_TEXT_2: 'No domain entry is required.',
  PANEL_DISPLAY_START_END_DATE: 'Start date / End date',
  PANEL_DISPLAY_START_END_DATE_VALIDATION:
    'Please select start date and end date',
  PANEL_ITEM_LAYOUT_VALIDATION: 'Please enter a range of 1-10',
  SELECTED_POST_LIST: 'Selected post list',
  CREATIVES_COUNT: 'Creatives count',
  PANEL_ORDER_LABEL: 'Arrange the order of the posts',
  CONSENTED_POSTS: 'Consented posts',
  UNCONSENTED_POSTS: 'Unconsented posts',
  UNCONSENTED_POSTS_ALERT:
    '※Cannot appear in the UGC panel even if it exists in the list unless the creator consents to it',
  DISPLAY: 'Display',
  HIDE: 'Hide',
  ITEM_COUNT_FOR_PC: 'PC size',
  ITEM_COUNT_FOR_TABLET: 'Tablet size',
  ITEM_COUNT_FOR_MOBILE: 'Mobile size',
  PC: 'PC',
  TABLET: 'Tablet',
  MOBILE: 'Mobile',
  BRAND_NOT_FOUND: 'Brand not found',
  CONSENT_URL_DESCRIPTION:
    'Are you sure you want to create a consent URL for this post?',
  SUCCESSFULLY_CONSENT_URL_CREATED: 'Successfully created consent URL',
  SUCCESSFULLY_CONSENT_URL_COPIED: 'Successfully copied consent URL',
  WAY_TO_INTRODUCE_UGC: 'Way to introduce UGC',
  WAY_TO_INTRODUCE_UGC_SHOPIFY: 'Install shopify App',
  WAY_TO_INTRODUCE_UGC_OWN_EC_GLOBAL_SNIPPET:
    'Global Snippet (Preferably put in <head> tag)',
  WAY_TO_INTRODUCE_UGC_OWN_EC_UGC_SET_SNIPPET:
    'UGC Set snippet (Put where you want to show the UGC set)',
  WAY_TO_INTRODUCE_UGC_OWN_EC_GTM_SCRIPT:
    'GTM Script(Script to put in the GTM tag custom HTML)',
  WAY_TO_INTRODUCE_UGC_OWN_EC_UGC_DIV: 'UGC Element DIV',
  SUCCESSFULLY_SET_UPDATED: 'Successfully updated UGC set',
  SET_UPDATE_COMFIRMATION_DESCRIPTION:
    'Are you sure you want to update this UGC set?',
  EDIT_SET_PAGE_TITLE: 'Edit UGC set',
  SORT_SET_TITLE: 'Sort consented posts',
  SORT_SET_DESC: (count: string) => `(${count} unconsented items)`,
  SORT_SET_ITEMS_ALERT: 'Only consented items can be sorted',
  STORE_ID: 'Store ID',
  LIST_ID: 'List ID',
  SET_ID: 'Set ID',
}
const payment = {
  CREATE_REPORT: 'Successfully created report',
  UPDATE_REPORT: 'Successfully updated report',
  PUBLISH_REPORTS: 'Successfully published reports',
  APPROVE_REPORTS: 'Successfully approved reports',
  STATUS_UNREVIEWED: 'Unreviewed',
  STATUS_APPROVED: 'Approved',
  STATUS_MISSING_DATA: 'Missing data',
  STATUS_REPORT_GENERATED: 'Report generated',
  STATUS_PUBLISHED: 'Published',
  REGISTRATION_INVOICE_NUMBER: 'Registration Invoice Number',
  APPROVED_AT: 'Approved At',
  PAYMENT_AMOUNT: 'Payment Amount',
  TARGET_YEAR: 'Target Year',
  BANK_ACCOUNT: 'Bank account',
  INVOICE: 'Invoice',
}
const common = {
  SERVER_ERROR:
    'A server error has occurred. Please contact the administrator.',
}

const en = {
  brand,
  campaign,
  client,
  influencer,
  product,
  warehouse,
  ugc,
  payment,
  button: button_en,
  common,

  fileupload: {
    titulo: 'File Upload',
    path: 'Folder path',
    picker: 'File picker',
    submit: 'Save form',
    select: 'Select and drag and drop a file',
    search: 'Search for anything',
    home: 'Home',
    name: 'File Name',
    uploadMessage: 'File uploaded successfully',
    errorMessage: 'An error occurred while uploading the file',
    fileURL: 'Uploaded File URL',
    remove: 'Remove',
  },

  // 'Account management': 'Account management',
  // Activate: 'Activate',
  DASHBOARD__AVERAGE_FOLLOWERS: 'Average followers',
  DASHBOARD__AVERAGE_TIKTOK_FOLLOWERS: 'Tiktok avg followers',
  DASHBOARD__AVERAGE_INSTAGRAM_FOLLOWERS: 'Instagram avg followers',
  // Campaign: 'Campaign',
  header: {
    ID: 'ID',
    COMPANY_NAME: 'Company Name',
    BRAND_NAME: 'Brand Name',
    PRODUCT_NUMBER: 'Product Number',
    PRODUCT_NAME: 'Product Name',
  },

  label: {
    ID: 'ID',
    BRAND: 'Brand',
    BRAND_NAME: 'Brand Name',
    BRAND_LOGO: 'Brand Logo',
    COMPANY: 'Company',
    COMPANY_NAME: 'Company Name',
    CONTRACT_TYPE: 'Plan',
    STORE: 'Store',
    STORE_NAME: 'Store Name',
    DOMAIN: 'Domain',
    CART_URL: 'CART URL',
    THANKS_URL: 'THANKS URL',
    WEBSITE_ID: 'Website ID',
    CREATED_AT: 'Created At',
    UPDATED_AT: 'Updated At',
    BRAND_ID: 'Brand ID',
    ITEMS: 'Items',
    LIST_NAME: 'List Name',
  },

  /** General */
  GENERAL__ACTION: 'Action',
  GENERAL__ADD: 'Add',
  GENERAL__AGE: 'Age',
  GENERAL__BRAND: 'Brand',
  /** Admin */
  ADMIN__BRAND_INFORMATION: 'Brand information',
  ADMIN__BRAND_NAME: 'Brand name',
  /** Brand */
  BRAND_ID: 'Brand ID',
  BRAND_NAME: 'Brand Name',
  BRAND__ACTIVE: 'Active',
  BRAND__APPROVED: 'Approved',
  BRAND__BRAND_LOGO: 'Brand logo',
  BRAND__BRAND_NAME: 'Brand name',
  BRAND__ADD: 'Add brand',
  BRAND__SAME_BRAND_WARNING: 'A brand with the same brand already exists',
  BRAND__UPLOAD_LOGO: 'Please upload an image',
  /** Campaign */
  CAMPAIGN__ALL_APPLICANTS: 'All applicants',
  CAMPAIGN__ALL_DEAL_PARTICIPANTS: 'All deal participants',
  CAMPAIGN__SUBMIT_REQUEST: 'Apply',
  CAMPAIGN__REJECT_PARTICIPANT: 'Reject participant',
  CAMPAIGN__PARTICIPANT__BRAND_COMPATIBILITY: 'Brand compatibility',
  CAMPAIGN__BRAND_NAME: 'Brand name',
  CAMPAIGN__CAMPAIGN_ADVANCE_SETTINGS: 'Campaign advanced setting',
  CAMPAIGN__CAMPAIGN_BASIC_SETTINGS: 'Campaign basic setting',
  CAMPAIGN__INFLUENCER_INFO: 'Influencer information',
  CAMPAIGN__PRODUCT_AND_SERVICE: 'Products & service',
  CAMPAIGN__TASKS: 'Tasks',
  CAMPAIGN__SCHEDULES: 'Schedules',
  CAMPAIGN__CAMPAIGN_DETAILS: 'Campaign details',
  CAMPAIGN__CAMPAIGN_ID: 'Campaign ID',
  CAMPAIGN__CAMPAIGN_LIST: 'Campaign list',
  CAMPAIGN__CAMPAIGN_NAME: 'Campaign name',
  CAMPAIGN__CAMPAIGN_OUTLINE: 'Campaign outline',
  CAMPAIGN__CAMPAIGN_PRODUCT_SERVICE: 'Campaign product/service',
  CAMPAIGN__CAMPAIGN_REPORT: 'Campaign report',
  CAMPAIGN__PRETASK: 'Pretask list',
  CAMPAIGN__PRETASK_REVIEW_STATUS: 'Review status',
  CAMPAIGN__PRETASK_DETAILS: 'Pretask detail',
  CAMPAIGN__PRETASK_MODIFICATION_REQUEST: 'Modification request',
  CAMPAIGN__APPROVE_PRETASK: 'Approve',
  CAMPAIGN__DENY_PRETASK: 'Deny',
  CAMPAIGN__CLOSE_PRETASK_SUBMISSION: 'Close pretask submission',
  CAMPAIGN__CLOSED_PRETASK_SUBMISSION: 'Closed pretask submission',
  CAMPAIGN__REVIEWED: 'Reviewed',
  CAMPAIGN__ADMIN_REVIEW: 'Admin review',
  CAMPAIGN__LAW_REVIEW: 'Law review',
  CAMPAIGN__LAW_REVIEWED: 'Law reviewed',
  CAMPAIGN__CLIENT_REVIEW: 'Client review',
  CAMPAIGN__CLIENT_REVIEWED: 'Client reviewed',
  CAMPAIGN__TASK_DETAILS: 'Task detail',
  CAMPAIGN__TASK_CHECK: 'Task check',
  CAMPAIGN__FIX_REWARD_AMOUNT: 'Fix reward amount',
  CAMPAIGN__STATUS_OF_REWARD_AMOUNT_FIXED: 'Status of reward amount fixed',
  CAMPAIGN__COMPLETED_PAYMENT_CONFIRMATION: 'Completed confirmation',
  CAMPAIGN__DESIRED_REWARD_AMOUNT: 'Desired reward amount',
  CAMPAIGN__NUMBER_OF_PARTICIPANTS: 'Number of participants',
  CAMPAIGN__ARCHIVE_CAMPAIGN: 'Archive campaign',
  CAMPAIGN__WINNER_SELECTIOM_METHOD: 'Selection method',
  CAMPAIGN__VERIFIED_INVOICE_NUMBER: 'Verified invoice number',
  CAMPAIGN__VERIFIED_INVOICE_NUMBER_COMPANY:
    'Verified invoice number (Company)',
  CAMPAIGN__VERIFIED_INVOICE_NUMBER_PRIVATE:
    'Verified invoice number (Private)',
  CAMPAIGN__INVOICE: 'Invoice',
  CAMPAIGN__DEAL_MODAL_TITLE: 'Send deal message',
  CAMPAIGN__DEAL_MODAL_DESCRIPTION:
    'Will you confirm the winners and send them an email?',

  /** Client */
  CLIENT__ADD_NEW: 'Add new client',
  CLIENT__ADDRESS: 'Address',
  CLIENT__ALL: 'All clients',
  CLIENT__ALL_MEMBERS: 'All members',
  CLIENT__CHANGE_MEMBER_ROLE:
    'Are you sure you want to change the role of this user?',
  CLIENT__REMOVE_MEMBER: 'Are you sure you want to delete this member?',
  /** Fan Marketing */
  fanMarketingTableHeader: {
    LIST_NAME: 'List Name',
    BRAND_NAME: 'Brand Name',
    ACTIONS: 'Actions',
    USERNAME: 'Username',
    FOLLOWERS: 'Followers',
    FOLLOWS: 'Follows',
    MEDIA_COUNT: 'Media Count',
  },
  FAN_MARKETING__ADD_BUTTON: 'Add to list',
  FAN_MARKETING__VIEW_BUTTON: 'View List',
  FAN_MARKETING__EDIT_BUTTON: 'Edit List',
  FAN_MARKETING__DELETE_BUTTON: 'Delete List',
  FAN_MARKETING__CREATE_LIST: 'Create Fan List',
  FAN_MARKETING__EDIT_LIST: 'Edit Fan List',
  FAN_MARKETING__MODAL_CREATE_TITLE: 'Create List',
  FAN_MARKETING__MODAL_UPDATE_TITLE: 'Update List',
  FAN_MARKETING__MODAL_CREATE_DESC:
    'Are you sure you want to create a new fan list?',
  FAN_MARKETING__MODAL_UPDATE_DESC:
    'Are you sure you want to update this fan list?',
  FAN_MARKETING__ADD_INFLUENCER: 'Add influencer',
  FAN_MARKETING_ADD_INSTAGRAM: 'Add instagram account',
  FAN_MARKETING_ADD_TIKTOK: 'Add tiktok account',
  FAN_MARKETING_ADD_USERNAME_LIST: 'Add usernames',
  FAN_MARKETING_ADD_USERNAME_TEXT: 'Add usernames with comma',
  /** Influencer */
  INFLUENCER__AVG_COMMENTS: 'Average number of comments',
  INFLUENCER__AVG_LIKES: 'Average number of likes',
  INFLUENCER__AVG_VIEWS: 'Average number of views',

  /** Influencer Search */
  INFLUENCER__SEARCH__FOLLOWERS: 'Follower Count',
  INFLUENCER__SEARCH__AGE_RATIO: 'Age Ratio',
  INFLUENCER__SEARCH__GENDER_RATIO: 'Gender Ratio',
  INFLUENCER__SEARCH__LAST_ACTIVITY: 'Last Activity',
  INFLUENCER__SEARCH__10S: '10s',
  INFLUENCER__SEARCH__20S: '20s',
  INFLUENCER__SEARCH__30S: '30s',
  INFLUENCER__SEARCH__40S: '40s or more',
  INFLUENCER__SEARCH__AGE_GROUP: 'Age Group',
  INFLUENCER__SEARCH__CREDIBILITY_SCORE: 'Credibility Score',
  INFLUENCER__SEARCH__APPLIED_CAMPAIGN_COUNT: 'Applied Campaign Count',
  INFLUENCER__SEARCH__WON_CAMPAIGN_COUNT: 'Won Campaign Count',
  INFLUENCER__SEARCH__WOMAN_FILTER: 'Filter With Woman Follower Ratio',
  INFLUENCER__SEARCH__JP_FOLLOWER_FILTER: 'Filter With JP Followers',
  INFLUENCER__SEARCH__WINNING_RATE_FILTER: 'Filter With Winning Rate',
  INFLUENCER__SEARCH__WINNING_RATE: 'Winning Rate',
  INFLUENCER__SEARCH__ACTIVITY_3_MONTHS:
    'Activities within the last three months',
  INFLUENCER__SEARCH__CAMPAIGN_3_MONTHS:
    'Campaigns joined within the last three months',
  INFLUENCER__SEARCH__JP_FOLLOWER: 'JP Followers',
  INFLUENCER__SEARCH__PLATFORM: 'SNS Platform',
  INFLUENCER__SEARCH__SEARCH___: 'Search...',
  INFLUENCER__SEARCH__SEARCH: 'Search',
  INFLUENCER__SEARCH__SEND_DM: 'Send DM',

  INFLUENCER__PERSONAL__INFLUENCER_ID: 'Influencer ID',

  INSTAGRAM__SEARCH__FORMAT: 'Post Format',
  INSTAGRAM__SEARCH__IMAGE: 'Image',
  INSTAGRAM__SEARCH__ALBUM: 'Carousel Album',
  INSTAGRAM__SEARCH__VIDEO: 'Video',
  INSTAGRAM__SEARCH__REEL: 'Reel',
  INSTAGRAM__SEARCH__LIKE: 'Like Count',
  INSTAGRAM__SEARCH__COMMENT: 'Comment Count',
  INSTAGRAM__SEARCH__TEXT_IN_IMAGE: 'Text In Image',
  INSTAGRAM__SEARCH__CONTAIN: 'Contain',
  INSTAGRAM__SEARCH__NOT_CONTAIN: 'Not Contain',
  INSTAGRAM__SEARCH__DATE: 'Post Date',

  /** UGC */
  ugcTableHeader: {
    STORE_NAME: 'Store Name',
    DOMAIN: 'Domain',
    BRAND_NAME: 'Brand Name',
    ACTIONS: 'Actions',
    LIST_NAME: 'List Name',
    LIST_TYPE: 'List Type',
  },

  UGC__VIEW_BUTTON: 'View Store',
  UGC__EDIT_BUTTON: 'Edit Store',
  UGC__CREATE_STORE: 'Create Store',
  UGC__EDIT_STORE: 'Edit Store',
  UGC__TOTAL_HITS: 'Total Hits',
  UGC__EDIT_STORE_MODAL: 'Are you sure you want to update this store?',
  UGC__CREATE_STORE_MODAL: 'Are you sure you want to create a new store?',
  UGC__VALIDATION_STORE_NAME: 'A store with the same name already exists.',
  UGC__VALIDATION_LONG_LIST_NAME: 'List name is too long',
  UGC__VALIDATION_SHORT_LIST_NAME: 'List name is too short',
  UGC__VALIDATION_BRAND_NAME: 'Please select a brand',
  UGC__PLACEHOLDER_LIST_NAME: 'Please enter between 3-100 characters',
  UGC__VALIDATION_URL: 'Please start with "/"',
  UGC__INSTAGRAM_POST: 'Feed',
  UGC__INSTAGRAM_STORY: 'Story',
  UGC__INSTAGRAM_REEL: 'Reel',
  UGC__CUSTOM_SHOP: 'Custom Shop',
  UGC__SHOPIFY_SHOP: 'Shopify Shop',
  UGC__SET_NAME: 'Set Name',
  UGC__PANEL_TYPE: 'Panel Type',
  UGC__VALIDATION_LONG_PANEL_NAME: 'Panel name is too long',
  UGC__VALIDATION_SHORT_PANEL_NAME: 'Panel name is too short',
  UGC__VALIDATION_SELECT_SHOP: 'Please select a shop',
  UGC__VALIDATION_SELECT_POST_LIST: 'Please select a post list',
  UGC__VALIDATION_TARGET_URL: 'Please enter a valid URL',
  UGC__PLACEHOLDER_PANEL_NAME: 'Please enter between 3-100 characters',
  UGC__SET_PAGE_TITLE_1: 'UGC Set',
  UGC__SET_PAGE_TITLE_2: 'UGC Set Layout',
  UGC__SET_PAGE_TITLE_3: 'Confirmation Page',
  UGC__ENTER_SET_NAME: 'Enter UGC set name',
  UGC__SELECT_FORMAT: 'Select format of shop',
  UGC__SELECT_SHOP: 'Select a shop',
  UGC__SELECT_POST_LIST: 'Select a post list',
  UGC__ENTER_TAG_ID: 'Enter embedded tag id',
  UGC__ENTER_PAGE_URL:
    'Enter the URL of the page where you would like to place the UGC',
  UGC__SET_HEADLINE: 'UGC Title',
  UGC__PLACEHOLDER_HEADLINE:
    'Please enter the title you will display in the UGC',
  UGC__SET_SUBHEADLINE: 'UGC Subtitle',
  UGC__PLACEHOLDER_SUBHEADLINE:
    'Please enter the subtitle you will display in the UGC',
  UGC__SELECT_DISPLAY_FORMAT: 'Select display format',
  UGC__SHOW_CAPTION: 'Do you want to show caption?',
  UGC__SHOW_COMMENT_COUNT: 'Do you want to show comment count?',
  UGC__SHOW_LIKE_COUNT: 'Do you want to show like count?',
  UGC__ITEM_COUNT_FOR_PC: 'Enter item counts to display for PC size',
  UGC__ITEM_COUNT_FOR_TABLET: 'Enter item counts to display for tablet size',
  UGC__ITEM_COUNT_FOR_MOBILE: 'Enter item counts to display for mobile size',
  UGC__HORIZONTAL_ITEM_COUNT: 'Horizontal',
  UGC__VERTICAL_ITEM_COUNT: 'Vertical',
  UGC__SELECTED_SHOP: 'Selected Shop',
  UGC__DISPLAY_FORMAT: 'Display Format',
  UGC__DISPLAY_CAPTION: 'Show Caption',
  UGC__DISPLAY_COMMENT_COUNT: 'Show Comment Count',
  UGC__DISPLAY_LIKE_COUNT: 'Show Like Count',

  ugcItem: {
    UGC__LIKE_COUNT: 'Like Count',
    UGC__COMMENT_COUNT: 'Comment Count',
    UGC__SAVE_COUNT: 'Save Count',
  },

  /** Product */
  PRODUCT__ADD: 'Add product',
  PAYMENT__MANAGEMENT: 'Payment Management',
  /** Warehouse */
  WAREHOUSE__ARRIVAL_COMPLETED: 'Arrival completed',
  WAREHOUSE__BEFORE_ARRIVAL: 'Before arrival',
  WAREHOUSE__CAMPAIGN_ID: 'Campaign id',
  WAREHOUSE__PRODUCT_NAME: 'Product Name',
  WAREHOUSE__PRODUCT_NUMBER: 'Product Number',

  ACTION: 'Action',
  APPROVE: 'Approve',
  APPROVED: 'Approved',
  ARCHIVE: 'Archive',
  ARCHIVED: 'Archived',
  BIRTHDATE: 'Birthdate',
  BLACKLIST_INSERT: 'Put On Blacklist',
  BLACKLIST_REMOVE: 'Remove From Blacklist',
  BLACKLIST: 'Blacklist',
  'Before selection': 'Before selection',
  CAMPAIGN__ARCHIVE_MESSAGE: 'Archive this campaign.',
  CAMPAIGN__ID: 'CAMPAIGN ID',
  CONFIRM_MESSAGE: 'Are you sure?',
  CAMPAIGN__SCHEDULE: 'Campaign schedule',
  CAMPAIGN__TASK: 'Campaign task',
  CAMPAIGN__TYPE: 'Campaign type',
  CANCEL: 'Cancel',
  CHANGE_PASSWORD: 'Change password',
  CHANGE_ROLE: 'Change role',
  CHAT: 'Chat',
  CHECKBOX: 'checkbox',
  SELECT_LANGUAGE: 'Choice language',
  SELECT_FILE: 'Choose file',
  CITY: 'City',
  CLEAR: 'Clear',
  CLIENT__MANAGEMENT: 'Client management',
  CLIENT__MEMBER: 'Member',
  CLIENT__OWNER: 'Admin',
  CLOSE: 'Close',
  PRODUCT_PRICE: 'Commodity price',
  COMPANY_INFORMATION: 'Company information',
  COMPANY_NAME: 'Company name',
  COMPANY_ID: 'Company ID',
  REGISTRATION_COMPLETED: 'Completion of registration',
  PASSWORD_CONFIRMATION: 'Confirmation password',
  CONNECT_TO_BRAND: 'Connect to brand',
  CONTACT_HERE: 'Contact here',
  CONTRACT_PLAN: 'Contract plan',
  CREATE: 'Create',
  CREATED_AT: 'Created At',
  CREATE_NEW: 'Create a new one',
  CREATE_BRAND: 'Create brand',
  CREATE_STORE: 'Create Store',
  UPDATE_STORE: 'Update Store',
  CREATE_CAMPAIGN: 'Create campaign',
  CREATE_ORIENTATION_SHEET: 'Create Orientation',
  CREATE_COMPANY: 'Create company',
  CREATE_PRODUCT: 'Create product',
  CREDIBILITY: 'Credibility',
  COPY: 'Copy',
  'Current password': 'Current password',
  'Current role': 'Current role',
  'Campaign report count': 'Campaign report count',
  'Chat list': 'Chat list',
  'Unread Count': 'Unread Count',
  Dashboard: 'Dashboard',
  Day: 'Day',
  Deactivate: 'Deactivate',
  Deactivated: 'Deactivated',
  'Deadline for draft submission': 'Deadline for draft submission',
  'Deadline for participation': 'Deadline for participation',
  'Deadline for selection': 'Deadline for selection',
  'Deadline for SNS posting': 'Deadline for SNS posting',
  'Deal participants': 'Deal participants',
  Defectives: 'Defectives',
  DELETE: 'Delete',
  DISABLE: 'DISABLE',
  'Delivery completed': 'Delivery completed',
  'Delivery list': 'Delivery list',
  'Delivery management': 'Delivery management',
  'Delivery target': 'Delivery target',
  Detail: 'Detail',
  'Detail filter': 'Detail filter',
  'Direct shipping to influencers': 'Direct shipping to influencers',
  'Disclosure range': 'Disclosure range',
  'Do you create a product?': 'Do you create a product?',
  CONFIRMATION__CREATE_BRAND_MESSAGE: 'Do you create a brand?',
  'Do you save a company?': 'Do you save a company?',
  'Do you send a registration email?': 'Do you send a registration email?',
  'Do you send products?': 'Do you send products?',
  CONFIRMATION__UPDATE_BRAND_MESSAGE: 'Do you want to update the brand?',
  'Do you update a product?': 'Do you update a product?',
  'Do you update company info?': 'Do you update company info?',
  'Do you update delivery info?': 'Do you update delivery info?',
  'Do you update inspection info?': 'Do you update inspection info?',
  'Do you update stock info?': 'Do you update stock info?',
  'Due date for review': 'Due date for review',
  Draft: 'Draft',
  DRAFT: 'Draft',
  DROPDOWN_MESSAGE: 'Select from list',
  Duplicate: 'Duplicate',
  Edit: 'Edit',
  EDIT_PRODUCT: 'Edit product',
  EDIT_COMPANY_INFORMATION: 'Edit company information',
  'Edit profile': 'Edit profile',
  'Email address': 'Email address',
  'End date': 'End date',
  'ending-order': 'Ending Order',
  'Engagement rate': 'Engagement rate',
  Essentials: 'Essentials',
  'Expected shipping date': 'Expected shipping date',
  'Export excel': 'Export excel',
  'Export CSV': 'Export CSV',
  'Export all participants': 'Export all participants',
  'Export deal participants': 'Export deal participants',
  'Export report': 'Export report',
  'Export media': 'Export media',
  'Enter child information': 'Enter child information',
  'Enter date of birth': 'Enter date of birth',
  Filter: 'Filter',
  FINISHED: 'Finished',
  'First name': 'First name',
  FIRST_COME_FIRST_SERVED: 'First come first served',
  FREE_SELECTION: 'Free selection',
  OTHER: 'Other',
  FOLLOWERS: 'Followers',
  'Follower growth rate': 'Follower growth rate',
  'For companies': 'For companies',
  'For influencers': 'For influencers',
  'Forgot password?': 'Forgot password?',
  SEND_MAGIC_LINK: 'Send Magic Link',
  SIGN_IN_MAGIC_LINK: 'Sign in with Magic Link',
  SIGN_IN_SMS: 'Sign in with SMS',
  OTP_MESSAGE: 'Please enter the OTP sent to your phone number.',
  SEND_SMS_MESSAGE: 'Send SMS',
  VERIFY_OTP_MESSAGE: 'Verify OTP',
  Gender: 'Gender',
  Gifting: 'Gifting',
  Hashtags: 'Hashtags',
  Thumbnail: 'Thumbnail',
  APPLIED_CAMPAIGNS: 'Applied Campaigns',
  LAST_APPLIED_CAMPAIGN_DATE: 'Last Applied Campaign Date',
  CHOSEN_CAMPAIGNS: 'Chosen Campaigns',
  PERMALINK: 'Permalink',
  INFLUENCER_URL: 'Influencer URL',
  COMMENTS: 'Comments',
  LIKES: 'Likes',
  ENGAGEMENT: 'Engagement',
  IMPRESSIONS: 'Impressions',
  REACH: 'Reach',
  SAVED: 'Saved',
  VIDEO_VIEWS: 'Video Views',
  ENGAGEMENT_RATE: 'Engagement Rate',
  DOWNLOAD_EXCEL: 'Download Excel',
  'LS attribution': 'LS attribution',
  'LS mention': 'LS mention',
  'LS hashtags': 'LS hashtags',
  'PR tag': 'PR tag',
  'Legal check': 'Legal check',
  'Branded Content': 'Branded Content',
  'I agree with the Privacy Policy': 'I agree with the Privacy Policy',
  Impression: 'Impression',
  'Including BK when export': 'Including BK when export',
  'In-house product_numbers': 'In-house product_numbers',
  Influencer: 'Influencer',
  INFLUENCER__ID: 'INFLUENCER ID',
  'Influencer info': 'Influencer info',
  'Influencer list': 'Influencer list',
  'Influencer personal check': 'Personal check',
  'Influencer antisocial check': 'Anti-social check',
  'Influencers with a high engagement rate':
    'Influencers with a high engagement rate',
  'Influencers with a large number of posts':
    'Influencers with a large number of posts',
  'Influencers with a lot of likes': 'Influencers with a lot of likes',
  'Influencers with many applications': 'Influencers with many applications',
  'Influencers with many followers': 'Influencers with many followers',
  INPUT_NUMERICAL_VALUE: 'Please enter Numeric Value',
  'Inspection completed': 'Inspection completed',
  'Inspection management': 'Inspection management',
  'Instagram registered person': 'Instagram registered person',
  Invite: 'Invite',
  'Invite new member': 'Invite new member',
  'Is this pre campaign?': 'Is this pre campaign?',
  'Is this semi pre campaign?': 'Is this semi pre campaign?',
  'Last name': 'Last name',
  'Lemon Square active user': 'Lemon Square active user',
  'Lemon Square support': 'Lemon Square support',
  'Phone number verification status': 'Phone number verification status',
  'Pre-task list': 'post draft list',
  'Pre-task ID': 'Pre-task ID',
  'Start of pretask posting': 'Start of pretask posting',
  Label: 'Attribute',
  Date: 'Date',
  Value: 'Value',
  'Deadline for pretask': 'Deadline for pretask',
  Verified: 'Verified',
  'Not verified': 'Not verified',
  'Identification process status': 'Identification process status',
  'Identification result status': 'Identification result',
  'AML risk check process status': 'AML risk check process status',
  'AML risk check result status': 'AML risk check result',
  'Status does not exist': 'Status does not exist',
  'Value does not exist': 'Does not exist',
  Links: 'Links',
  'Link pattern': 'Link pattern',
  'Login as this user': 'Login as this user',
  'Login here': 'Login here',
  Logout: 'Logout',
  'Logout message': 'Your session has expired. Please login again.',
  Mail: 'Mail',
  'Mark as read': 'Mark as read',
  'Mark as unread': 'Mark as unread',
  'Maximum 99 items': 'Maximum 99 items',
  'Member management': 'Member management',
  Memo: 'Memo',
  Mentions: 'Mentions',
  Menu: 'Menu',
  Month: 'Month',
  'Multiple selections allowed': 'Multiple selections allowed',
  'My profile': 'My profile',
  Name: 'Name',
  'New password': 'New password',
  No: 'No',
  'No users': 'No users',
  'Number of arrivals': 'Number of arrivals',
  'Number of applicants': 'Number of applicants',
  'Number of participant joined': 'Number of participant joined',
  'Number of champaign creatives': 'Number of champaign creatives',
  'Number sum for campaign participants':
    'Number sum for campaign participants',
  'Number sum for campaign creatives': 'Number sum for campaign creatives',
  SAVES: 'Saves',
  'Number of campaign applications': 'Number of campaign applications',
  'Number of comments': 'Number of comments',
  'Number of distribution per person': 'Number of distribution per person',
  'Number of defectives': 'Number of defectives',
  'Number of deliveries': 'Number of deliveries',
  'Number of followers': 'Number of followers',
  'Number of winners followers': 'Number of deal followers',
  'Number of goods': 'Number of goods',
  'Number of inspection': 'Number of inspection',
  'Number of likes': 'Number of likes',
  'Number of passing inspection': 'Number of passing inspection',
  'Number of posts': 'Number of posts',
  'Number of provides': 'Number of provides',
  'Number of registrants': 'Number of registrants',
  'Number of sending back': 'Number of sending back',
  'Number of share': 'Number of share',
  'Number of shipping': 'Number of shipping',
  'Number of targets': 'Number of targets',
  'Number of uninspected': 'Number of uninspected',
  'Number of videos': 'Number of videos',
  'Number of winners': 'Number of winners',
  'Expected number of winners': 'Expected number of winners',
  'Number of actual winners': 'Number of actual winners',
  'Number of estimated followers': 'Number of estimated followers',
  'Number of estimated following': 'Number of estimated following',
  'Number of estimated likes': 'Number of estimated likes',
  'Number of accounts': 'Number of accounts',
  'Current instagram': 'Current instagram',
  'Current tiktok': 'Current tiktok',
  Optional: 'Optional',
  'Order total': 'Order total',
  Overview: 'Overview',
  'Owned brand': 'Owned brand',
  'Payment List': 'Payment List',
  'Passing inspection': 'Passing inspection',
  'Participant status successfully changed':
    'Participant status successfully changed',
  Password: 'Password',
  'Package name': 'Package name',
  'Percentage of users who linked instagram':
    'Percentage of users who linked instagram',
  'Please select a chat to get started': 'Please select a chat to get started',
  'Please enter 7 characters': 'Please enter 7 characters',
  'Please enter the email address you would like to invite':
    'Please enter the email address you would like to invite',
  'Please enter the product number etc. managed in-house':
    'Please enter the product number etc. managed in-house',
  'Please enter up to 100 characters': 'Please enter up to 100 characters',
  'Please enter your information': 'Please enter your information',
  'Please fill modification request content':
    'Please fill modification request content.',
  'Please fill name and email address of the person you would like to invite':
    'Please fill name and email address of the person you would like to invite',
  'Please select brands': 'Please select brands',
  'Please wait for previous review': 'Please wait for previous review.',
  'Phone number': 'Phone number',
  'Popular campaign': 'Popular campaign',
  'Post URL': 'Post URL',
  'Post questionnaire': 'Post questionnaire',
  'Postal code': 'Postal code',
  'PR post': 'PR post',
  Platform: 'Platform',
  Precampaign: 'Precampaign',
  'Semi pre campaign': 'Semi pre campaign',
  Prefecture: 'Prefecture',
  PREFECTURE_HOKKAIDO: 'Hokkaido',
  PREFECTURE_AOMORI: 'Aomori',
  PREFECTURE_IWATE: 'Iwate',
  PREFECTURE_MIYAGI: 'Miyagi',
  PREFECTURE_AKITA: 'Akita',
  PREFECTURE_YAMAGATA: 'Yamagata',
  PREFECTURE_FUKUSHIMA: 'Fukushima',
  PREFECTURE_IBARAKI: 'Ibaraki',
  PREFECTURE_TOCHIGI: 'Tochigi',
  PREFECTURE_GUNMA: 'Gunma',
  PREFECTURE_SAITAMA: 'Saitama',
  PREFECTURE_CHIBA: 'Chiba',
  PREFECTURE_TOKYO: 'Tokyo',
  PREFECTURE_KANAGAWA: 'Kanagawa',
  PREFECTURE_NIIGATA: 'Niigata',
  PREFECTURE_TOYAMA: 'Toyama',
  PREFECTURE_ISHIKAWA: 'Ishikawa',
  PREFECTURE_FUKUI: 'Fukui',
  PREFECTURE_YAMANASHI: 'Yamanashi',
  PREFECTURE_NAGANO: 'Nagano',
  PREFECTURE_GIFU: 'Gifu',
  PREFECTURE_SHIZUOKA: 'Shizuoka',
  PREFECTURE_AICHI: 'Aichi',
  PREFECTURE_MIE: 'Mie',
  PREFECTURE_SHIGA: 'Shiga',
  PREFECTURE_KYOTO: 'Kyoto',
  PREFECTURE_OSAKA: 'Osaka',
  PREFECTURE_HYOGO: 'Hyogo',
  PREFECTURE_NARA: 'Nara',
  PREFECTURE_WAKAYAMA: 'Wakayama',
  PREFECTURE_TOTTORI: 'Tottori',
  PREFECTURE_SHIMANE: 'Shimane',
  PREFECTURE_OKAYAMA: 'Okayama',
  PREFECTURE_HIROSHIMA: 'Hiroshima',
  PREFECTURE_YAMAGUCHI: 'Yamaguchi',
  PREFECTURE_TOKUSHIMA: 'Tokushima',
  PREFECTURE_KAGAWA: 'Kagawa',
  PREFECTURE_EHIME: 'Ehime',
  PREFECTURE_KOCHI: 'Kochi',
  PREFECTURE_FUKUOKA: 'Fukuoka',
  PREFECTURE_SAGA: 'Saga',
  PREFECTURE_NAGASAKI: 'Nagasaki',
  PREFECTURE_KUMAMOTO: 'Kumamoto',
  PREFECTURE_OITA: 'Oita',
  PREFECTURE_MIYAZAKI: 'Miyazaki',
  PREFECTURE_KAGOSHIMA: 'Kagoshima',
  PREFECTURE_OKINAWA: 'Okinawa',
  'Preferred age': 'Preferred age',
  'Preferred gender': 'Preferred gender',
  'Preferred region': 'Preferred region',
  'Pretask review status successfully updated':
    'Pretask review status successfully updated',
  Product: 'Product',
  'Product detail': 'Product detail',
  'Product id': 'Product id',
  'Product list': 'Product list',
  'Product management': 'Product management',
  PRODUCT__PRODUCT_NUMBER: 'Product number',
  'Product shipment': 'Product shipment',
  'Product/Service image': 'Product/Service image',
  'Post date on SNS': 'Post date on SNS',
  Prohibitions: 'Prohibitions',
  'Publish term': 'Publish term',
  radio: 'radio',
  Reach: 'Reach',
  READY: 'Ready',
  'Register term': 'Register term',
  'Register user': 'Register user',
  'Registered person': 'Registered person',
  'Registration date': 'Registration date',
  REJECT: 'Reject',
  Report: 'Report',
  'Reported participants': 'Reported participants',
  Reset: 'Reset',
  Required: 'Required',
  'Request to write product-returning memo':
    'Please explain the reason of returning products',
  'Reward amount': 'Reward amount',
  'Reward amounts have been finalized': 'Reward amounts have been finalized',
  'Reward campaign or not': 'Reward campaign or not',
  'Reward type': 'Reward type',
  Role: 'Role',
  'Role after change': 'Role after change',
  'Running campaign': 'Running campaign',
  RUNNING: 'Running',
  'Saas client': 'Saas client',
  'Saas plan': 'Saas plan',
  SAVE: 'Save',
  'Save post URL': 'Save post URL',
  Search: 'Search',
  SEARCH_CONDITION: 'Search condition',
  'Search by IG account': 'Search by IG account',
  'Search account': 'Search account',
  'Search campaign': 'Search campaign',
  'Search content': 'Search content',
  'Search results': 'Search results',
  'Search with Campaign name, ID, brand name, etc.':
    'Search with Campaign name, ID, brand name, etc.',
  'See all': 'See all',
  Select: 'Select',
  'Select all': 'Select all',
  'Selected applicants': 'Selected applicants',
  'Select brand': 'Select brand',
  'Select company': 'Select company',
  'Selected applicants followers': 'Followers from selected applicants',
  Send: 'Send',
  'Send DM': 'Send DM',
  'Send DM to all participants': 'Send DM to all participants',
  'Send DM to deal participants': 'Send DM to deal participants',
  'Send back': 'Send back',
  'Send invitation email': 'Send invitation email',
  'Send registration email': 'Send registration email',
  'Set selected participants as deal participants':
    'Set selected participants as deal participants',
  'Setting spotlight': 'Setting spotlight',
  'Shipping instruction': 'Shipping instruction',
  'Show tasks and deadlines after winning':
    'Show tasks and deadlines after winning',
  'Sign in': 'Sign in',
  'Sign in with credentials': 'Sign in with credentials',
  'Sign up': 'Sign up',
  'Sign up with credentials': 'Sign up with credentials',
  'Similar kol': 'Similar kol',
  'Since last month': 'Since last month',
  'Since last week': 'Since last week',
  'SKU name': 'SKU name',
  'Product name': 'Product name',
  'SKU list': 'SKU list',
  'Some data are being edited. Please press the Update button.':
    'Some data are being edited. Please press the Update button.',
  'Show unread only': 'Show unread only',
  'Sort by latest message': 'Sort by latest message',
  'Sort by oldest message': 'Sort by oldest message',
  'Sort by A to Z': 'Sort by A to Z',
  'Sort by Z to A': 'Sort by Z to A',
  'ascending-order': 'ascending-order',
  'descending-order': 'descending-order',
  'Start date': 'Start date',
  'Start of draft submission': 'Start of draft submission',
  'Start of participation': 'Start of participation',
  'Start of SNS posting': 'Start of SNS posting',
  'starting-order': 'Starting Order',
  Sort_Order: 'order',
  Status: 'Status',
  'Status of campaign': 'Status of campaign',
  'Street, building name, etc': 'Street, building name, etc',
  'Stock list': 'Stock list',
  SUBMISSION: 'Submission',
  Submit: 'Submit',
  'Successfully saved': 'Successfully saved',
  'Successfully deleted': 'Successfully deleted',
  'Successfully updated': 'Successfully updated',
  Summary: 'Summary',
  'Support plan': 'Support plan',
  'Target group': 'Target group',
  Tags: 'Tags',
  Tasks: 'Tasks',
  text: 'text',
  'There is an additional fee as our handling.':
    'There is an additional fee as our handling.',
  'There is no data': 'There is no data',
  'There is no data to export': 'There is no data to export',
  'This brand also registers the following products':
    'This brand also registers the following products',
  'This is the product category that KOL selects when applying for a campaign.':
    'This is the product category that KOL selects when applying for a campaign.',
  'This pretask has modification request':
    'This pretask has modification request.',
  'This pretask is approved': 'This pretask is approved.',
  'This pretask is unreviewed': 'This pretask is unreviewed.',
  'TikTok registered person': 'TikTok registered person',
  Title: 'title-en',
  Total: 'Total',
  'Total campaigns': 'Total campaign',
  'Total clients': 'Total clients',
  'Total followers': 'Total followers',
  'Total influencers': 'Total influencers',
  'Total instagram campaigns': 'Total instagram campaigns',
  'Total instagram users': 'Total instagram users',
  'Total tiktok campaigns': 'Total tiktok campaigns',
  'Total tiktok users': 'Total tiktok users',
  'Total hits': 'Total Hits',
  Town: 'Town',
  // Unapproved: 'Unapproved',
  Unknown: 'Unknown',
  'Unit price': 'Unit price',
  'Unreported participants': 'Unreported participants',
  Unreview: 'Unreview',
  Unsubmitted: 'Unsubmitted',
  Unset: 'Unset',
  UNSUBSCRIBED_USER: 'This user has already unsubscirbed',
  UPDATE: 'Update',
  UPDATED_AT: 'Updated at',
  UPDATE_BRAND: 'Update brand',
  'Update product': 'Update product',
  'Update delivery info': 'Update delivery info',
  'Update inspection info': 'Update inspection info',
  'Warehouse inspection history': 'Warehouse inspection history',
  'Shipping product logs': 'Shipping product logs',
  'Shipping Registration History': 'Shipping Registration History',
  'Update stock info': 'Update stock info',
  'Upload excel': 'Upload excel',
  User: 'User',
  USERNAME: 'USERNAME',
  'User id': 'User id',
  'User information': 'User information',
  'User name': 'User name',
  View: 'View',
  'Visit campaign': 'Visit campaign',
  'We sent an email so please check your inbox':
    'We sent an email so please check your inbox',
  Week: 'Week',
  Website: 'Website',
  'Welcome!': 'Welcome!',
  'Welcome to lemon square': 'Welcome to Lemon Square',
  'Whether the product to be sent': 'Whether the product to be sent',
  'Without hyphen': 'Without hyphen',
  'Winning campaign count': 'Winning campaign count',
  'Years old': 'years old',
  Yes: 'Yes',
  'You can add or edit products to be shipped in the campaign.':
    'You can add or edit products to be shipped in the campaign.',
  'You can manage the shipping status of products.':
    'You can manage the shipping status of products.',
  'Under review': 'Under review',
  'Recruiting KOL': 'Recruiting KOL',
  'Recruitment completed / post collection':
    'Recruitment completed / post collection',
  'End of publication': 'End of publication',
  'Influencers with children': 'Influencers with children',
  'Personal information': 'Personal information',
  'SNS authentication': 'SNS authentication',
  'Last login date': 'Last login date',
  'Last application date': 'Last application date',
  'In preparation': '準備中',
  'The page you accessed is currently being prepared.':
    'The page you accessed is currently being prepared.',
  'Please wait for a while until it is available.':
    'Please wait for a while until it is available.',
  'Fan score': 'Fan score',
  "You've tried to send the verification email too many times":
    "You've tried to send the verification email too many times. Please try again later.",
  'Change email': 'Change email',
  'Confirm email': 'Confirm email',
  'Send Confirmation email': 'Send Confirmation email',
  "Email addresses don't match": "Email addresses don't match",
  'confirmation link sent':
    'A confirmation link was sent to your email. Please click on the link to verify your email address.',
  'confirmation link sent instruction':
    'An email was sent to your email. Please click on the link to verify your email address.',
  Email: 'Email',
  'Back to sign in': 'Back to sign in',
  'Request reset link': 'Request reset link',
  'Reset password link sent':
    'We have sent you an email with a link to reset your password.',
  'Wrong password': 'Wrong password',
  'Account not found': 'Account not found',
  'Confirm password': 'Confirm password',
  'Wrong credentials': 'Wrong credentials',
  'Already have an account': 'Do you have an account? Sign in',
  'Select influencers': 'Select influencers',
  'User blacklist status successfully changed':
    'User blacklist status successfully changed',
  Back: 'Back',
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
  GIFTING: 'Gifting',
  PAID_PROMOTION: 'Paid promotion',
  MALE: 'Male',
  FEMALE: 'Female',
  Unspecified: 'Unspecified',
  "10's": "10's",
  "20's": "20's",
  "30's": "30's",
  "40's": "40's",
  Necessary: 'Necessary',
  Unnecessary: 'Unnecessary',
  'Has product': 'Has Product',
  'No product': 'No Product',
  Do: 'Do',
  "Don't": "Don't",
  '¥ / follower': '¥ / follower',
  'Approve as pitcrew': 'Approve as pitcrew',
  'Approve campaign': 'Approve campaign',
  'Reject campaign': 'Reject campaign',
  Notifications: 'Notifications',
  Address: 'Address',
  'Address Verified': 'Address(Verified)',
  'Address Shipping': 'Address(Shipping)',
  'Zip Code': 'Zip code',
  'Notification management for CMS': 'Notification management for CMS',
  'Notification management for mobile application':
    'Notification management for mobile application',
  'Add notification about CMS': 'Add notification about CMS',
  'Add notification about mobile application':
    'Add notification about mobile application',
  Add: 'Add',
  'Notification list': 'Notification list',
  Destination: 'Destination',
  'Notification currently being delivered':
    'Notification currently being delivered',
  'Child information': 'Child information',
  'Fan marketing': 'Fan marketing',
  'Create new list': 'Create new list',
  'Select fan list': 'Select fan list',
  'Fan list': 'Fan list',
  'Edit fan list': 'Edit fan list',
  Change: 'Change',
  'List name is 255 characters or less': 'List name is 255 characters or less',
  'Delete fan list': 'Delete fan list',
  'Do you delete fan list?': 'Do you delete fan list?',
  'Fan list name': 'Fan list name',
  'Do you update campaign banner?': 'Do you update campaign banner?',
  'Do you delete campaign banner?': 'Do you delete campaign banner?',
  'Export selected participants': 'Export selected participants',
  RESET_ALL: 'Reset all',
  'Changes in the number of people by fan score':
    'Changes in the number of people by fan score',
  'male to female ratio of followers': 'male to female ratio of followers',
  'age ratio': 'age ratio',
  'age ratio of followers': 'age ratio of followers',
  Asc: 'Asc',
  Desc: 'Desc',
  'Gender ratio': 'Gender ratio',
  'Please fill approve message': 'Please fill approve message',
  'Number of selected posts': 'Number of selected posts',
  'Back to post selection': 'Back to post selection',
  'You can change the display order by dragging and dropping':
    'You can change the display order by dragging and dropping',
  NEXT: 'NEXT',
  'Panel name': 'UGC set name',
  'Display destination URL': 'Display destination URL',
  Format: 'Format',
  'Is this present campaign?': 'Is this present campaign?',
  Presentcampaign: 'Presentcampaign',
  'Create SNS share url': 'Create SNS share url',
  'SNS share url': 'SNS share url',
  'Do you want to set the SNS share URL?':
    'Do you want to set the SNS share URL?',
  'No setting': 'No setting',
  'Campaign pretask modification request(include lower check)':
    'Campaign pretask modification request(include lower check)',
  'Please wait for resubmission from kol':
    'Please wait for resubmission from kol',
  'No review required': 'No review required',
  'turn to client check': 'turn to client check',
  'Approve message': 'Approve message',
  'Denied reason': 'Denied reason',
  Content: 'Content',
  'Save memo': 'Save memo',
  'Update the review content of the pretask':
    'Update the review content of the pretask',
  pageTitles: {
    OVERVIEW: 'Overview',
    ACCOUNT: 'Account',
    ADMIN: 'Admin',
    ANALYTICS: 'Analytics',
    BRAND: 'Brand',
    EDIT_BRAND: 'Edit Brands',
    CAMPAIGN: 'Campaign',
    ORIENTATION: 'Orientation',
    UGC_STORE: 'Stores',
    UGC_POST_LIST: 'Posts',
    UGC_INSTAGRAM_POST: 'Posts',
    UGC_SET: 'UGC Sets',
    CHAT: 'Chat',
    CLIENT: 'Client',
    CREATE: 'Create',
    DASHBOARD: 'Dashboard',
    Delivery: 'Delivery',
    Details: 'Details',
    Edit: 'Edit',
    Error: 'Error',
    EXTERNAL_LINK: 'External Link',
    FAN_MARKETING: 'Fan Marketing',
    Influencer: 'Influencer',
    Inspection: 'Inspection',
    Instagram: 'Instagram',
    Login: 'Login',
    MY_COMPANY: 'My Company',
    OWN_MEMBER: 'Own Member',
    Performance: 'Performance',
    Product: 'Product',
    Register: 'Register',
    SEARCH_CONSOLE: 'Search Console',
    Shipping: 'Shipping',
    Spotlight: 'Spotlight',
    Stock: 'Stock',
    Tiktok: 'TikTok',
    Trend: 'Trend',
    Users: 'Users',
    Warehouse: 'Warehouse',
    'Uploaded file': 'Uploaded file',
  },
  FORMS: {
    PLACEHOLDERS: {
      campaignName: 'Campaign name',
    },
    LABELS: {
      campaignName: 'Campaign name',
    },
    HELP_TEXTS: {
      campaignName: '商品やサービスがわかりやすい名前にしてください',
    },
  },
  'Campaign Name': 'Campaign Name',
  'Campaign name description': 'Use a descriptive name for campaign',
  'Campaign brand section': 'Campaign brand section',
  'Please select a campaign brand': 'Please select a campaign brand',
  'Please select a brand image':
    'Please select a brand image (square image with sides of 600px to 1080px)',
  'Brand image section': 'Brand image',
  'Date of campaign publish': 'Date of campaign publish',
  'Is Secondary use permission needed?':
    'Would you like to use it for secondary use? (ad creative, SNS, storefront POP, etc.)',
  'Can notify on Instagram as advertisement?':
    'Is it okay if I announce it on Instagram of Lemon Square?',
  'Is Brand logo use allowed?':
    'May I use your company brand logo on our website and media materials as a clients track record?',
  'Is case study allowed?':
    'Would it be okay if I posted what you are going to do this time as an example of implementation?',
  'Number of Winners': 'Number of Winners',
  'Is pharmaceutical use?':
    'Is it a drug (OTC drugs and prescription drugs classified into categories 1, 2, and 3)?',
  'Is medical product use?': 'Food or Beverage?',
  'Product URL': 'Add the product URL',
  'Shipping Count': 'Shipping Count',
  'Price of Product': 'Enter the price of product',
  'Shipping Address': 'Return Address',
  'shipping address zip code': 'Shipping address zip code',
  'Should product be returned?':
    'Do you need to send it back when you have excess stock?',
  'Event Date': 'Event Date',
  'Event Name': 'Event Name',
  'Is Store visit present?': 'Is it a store-visit campaign?',
  'Is case study allowed? detail':
    'I would like to post the contents of the implementation this time as an implementation example.',
  'Product URL Info': 'Product URL',
  'Price Info': 'Price',
  'Is Open Price?': 'Is Open Price?',
  'Create Camapign Confirmation':
    'Are you sure you want to create this campaign?',
  'Name error message': 'Please enter between 3 and 100 characters',
  'Product URL error message': 'Please enter a valid URL!',
  'Orientation List': 'Orientation List',
  'Create New Orientation': 'Create New',
  'Campaign Type': 'Campaign Type',
  'Whether to display in the app': 'Whether to display in the app',
  'Information lifting date': 'Information lifting date',
  'Delivery Date': 'Delivery Date',
  'Is product type available?': 'Is there a product type selection?',
  'product type description':
    'If you have any, please list the sizes you can provide.',
  'Available sizes': 'Available sizes',
  'Updated At': 'Updated At',
  'Something went wrong': 'Something went wrong',
  'instagram username': 'Instagram username',
  'existed account': 'Aleady existed account',
  'invalid username': 'Invalid username',
  'Save insights manually': 'Save insights manually',
  plays: 'Plays',
  commentsCount: 'Comments',
  likeCount: 'Likes',
  saved: 'Saves',
  shares: 'Shares',
  reach: 'Reach',
  totalInteractions: 'Interactions',
  videoViewTotalTime: 'Watch time',
  avgWatchTime: 'Average watch time',
  engagement: 'Engagement',
  profileVisits: 'Profile visits',
  impressions: 'Impressions',
  exits: 'Exited',
  replies: 'Replies',
  tapsForward: 'Forward',
  profileActivity: 'Profile activity',
  swipeForward: 'Next story',
  Insight: 'Insight',
  'Check screenshots': 'Check screenshots',
  'Fix reward price': 'Fix reward price',
  Fix: 'Fix',
  'Don’t fix': 'Don’t fix',
  'Reward per follower': 'Reward per follower',
  'Fixed reward price': 'Fixed reward price',
  'The input is incorrect': 'The input is incorrect',
  'This field is required': 'This field is required',
  'There are some errors': 'There are some errors',
  Yen: 'Yen',
  Next: 'Next',
  'Enter identification information': 'Enter identification information',
  'Send private offer or not': 'Send private offer or not',
  Confirmation: 'Confirmation',
  'The data you entered on this page will be reset. Are you sure you want to go back to the previous page?':
    'The data you entered on this page will be reset. Are you sure you want to go back to the previous page?',
  'Is there a product to ship?': 'Is there a product to ship?',
  'Is direct shipping?': 'Is direct shipping?',
  'Open price setting': 'Open price setting',
  'Setting product': 'Setting product',
  'Do I need to select a product?': 'Do I need to select a product?',
  'If you don`t use this sku, please set it to 0 or null':
    'If you don`t use this sku, please set it to 0 or null',
  'Media submission availability': 'Media submission availability',
  'Add task': 'Add task',
  'Remove task': 'Remove task',
  'Task grouping': 'Task grouping',
  'Reset task grouping': 'Reset task grouping',
  'Task type': 'Task type',
  'Tasks marked as complete': 'Tasks marked as complete',
  'Have pretask or not': 'Have pretask or not',
  'Task group setting': 'Task group setting',
  'Skip legal check': 'Skip legal check',
  'Campaign basic setting': 'Campaign basic setting',
  'Campaign detail setting': 'Campaign detail setting',
  'Campaign product setting': 'Campaign product setting',
  'Campaign task setting': 'Campaign task setting',
  'Campaign schedule setting': 'Campaign schedule setting',
  'Open price': 'Open price',
  'Show in app': 'Show in app',
  'Not show in app': 'Not show in app',
  Existence: 'Existence',
  'Not existence': 'Not existence',
  Request: 'Request',
  'Not request': 'Not request',
  'Product content per person': 'Product content per person',
  Changed: 'Changed',
  'Update Camapign Confirmation': 'Update Camapign Confirmation',
  'Update without changing status': 'Update without changing status',
  'bgm title': 'bgm title',
  'bgm artist': 'bgm artist',
  'bgm reference': 'bgm reference',
  'Project Code': 'Project Code',
  'Save draft': 'Save draft',
  'Change status': 'Change status',
  'Do you update pretask status?': 'Do you update pretask status?',
  'Updated status': 'Updated status',
  'Content of the review': 'Content of the review',
  'Confirm content of the review': 'Confirm content of the review',
  'Reviewer role': 'Reviewer role',
  'Copy caption': 'Copy caption',
}

const chatEn = {
  OPEN_CHANNEL_SETTINGS__OPERATOR_TITLE: 'Channel Information',
  OPEN_CHANNEL_SETTINGS__OPERATOR_URL: 'URL',
  OPEN_CHANNEL_SETTINGS__PARTICIPANTS_ACCORDION_TITLE: 'Participants',
  OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_PANEL: 'Delete channel',
  OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_TITLE: 'Delete this channel',
  OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_SUBMIT: 'Delete',
  OPEN_CHANNEL_SETTINGS__PARTICIPANTS_TITLE: 'Participants',
  OPEN_CHANNEL_SETTINGS__EMPTY_LIST: 'No participants yet',
  OPEN_CHANNEL_SETTINGS__SEE_ALL: 'See all participants',
  OPEN_CHANNEL_SETTINGS__ALL_PARTICIPANTS_TITLE: 'All participants',
  OPEN_CHANNEL_SETTINGS__NO_TITLE: '(No title)',
  OPEN_CHANNEL_CONVERSATION__TITLE_PARTICIPANTS: 'participants',
  TRYING_TO_CONNECT: 'Trying to connect…',
  USER_PROFILE__MESSAGE: 'Message',
  USER_PROFILE__USER_ID: 'User ID',
  EDIT_PROFILE__TITLE: 'My profile',
  EDIT_PROFILE__IMAGE_LABEL: 'Profile image',
  EDIT_PROFILE__IMAGE_UPLOAD: 'Upload',
  EDIT_PROFILE__NICKNAME_LABEL: 'Nickname',
  EDIT_PROFILE__NICKNAME_PLACEHOLDER: 'Enter your nickname',
  EDIT_PROFILE__USERID_LABEL: 'User ID',
  EDIT_PROFILE__THEME_LABEL: 'Dark theme',
  MESSAGE_INPUT__PLACE_HOLDER: 'Enter message',
  MESSAGE_INPUT__PLACE_HOLDER__DISABLED: 'Chat is unavailable in this channel',
  MESSAGE_INPUT__PLACE_HOLDER__MUTED:
    'Chat is unavailable because you are being muted',
  MESSAGE_INPUT__QUOTE_REPLY__PLACE_HOLDER: 'Reply to message',
  CHANNEL__MESSAGE_LIST__NOTIFICATION__NEW_MESSAGE: 'new message(s) since',
  CHANNEL__MESSAGE_LIST__NOTIFICATION__ON: 'on',
  CHANNEL_SETTING__HEADER__TITLE: 'Channel information',
  CHANNEL_SETTING__PROFILE__EDIT: 'Edit',
  CHANNEL_SETTING__MEMBERS__TITLE: 'Members',
  CHANNEL_SETTING__MEMBERS__SEE_ALL_MEMBERS: 'All members',
  CHANNEL_SETTING__MEMBERS__INVITE_MEMBER: 'Invite users',
  CHANNEL_SETTING__MEMBERS__YOU: ' (You)',
  CHANNEL_SETTING__LEAVE_CHANNEL__TITLE: 'Leave channel',
  CHANNEL_SETTING__OPERATORS__TITLE: 'Operators',
  CHANNEL_SETTING__OPERATORS__TITLE_ALL: 'All operators',
  CHANNEL_SETTING__OPERATORS__TITLE_ADD: 'Add operator',
  CHANNEL_SETTING__MUTED_MEMBERS__TITLE: 'Muted members',
  CHANNEL_SETTING__BANNED_MEMBERS__TITLE: 'Banned members',
  CHANNEL_SETTING__FREEZE_CHANNEL: 'Freeze Channel',
  BUTTON__CANCEL: 'Cancel',
  BUTTON__DELETE: 'Delete',
  BUTTON__SAVE: 'Save',
  BUTTON__CREATE: 'Create',
  BUTTON__INVITE: 'Invite',
  BADGE__OVER: '+',
  MODAL__DELETE_MESSAGE__TITLE: 'Delete this message?',
  MODAL__CHANNEL_INFORMATION__TITLE: 'Edit channel information',
  MODAL__CHANNEL_INFORMATION__CHANNEL_IMAGE: 'Channel image',
  MODAL__CHANNEL_INFORMATION__UPLOAD: 'Upload',
  MODAL__CHANNEL_INFORMATION__CHANNEL_NAME: 'Channel name',
  MODAL__CHANNEL_INFORMATION__INPUT__PLACE_HOLDER: 'Enter name',
  MODAL__INVITE_MEMBER__TITLE: 'Invite member',
  MODAL__INVITE_MEMBER__SELECTEC: 'selected',
  MODAL__CHOOSE_CHANNEL_TYPE__TITLE: 'New channel',
  MODAL__CHOOSE_CHANNEL_TYPE__GROUP: 'Group',
  MODAL__CHOOSE_CHANNEL_TYPE__SUPER_GROUP: 'Super group',
  MODAL__CHOOSE_CHANNEL_TYPE__BROADCAST: 'Broadcast',
  MODAL__CREATE_CHANNEL__TITLE: 'New channel',
  MODAL__CREATE_CHANNEL__GROUP: 'Group',
  MODAL__CREATE_CHANNEL__SUPER: 'Super group',
  MODAL__CREATE_CHANNEL__BROADCAST: 'Broadcast',
  MODAL__CREATE_CHANNEL__SELECTED: 'selected',
  TYPING_INDICATOR__IS_TYPING: 'is typing...',
  TYPING_INDICATOR__AND: 'and',
  TYPING_INDICATOR__ARE_TYPING: 'are typing...',
  TYPING_INDICATOR__MULTIPLE_TYPING: 'Several people are typing...',
  CHANNEL_FROZEN: 'Channel frozen',
  PLACE_HOLDER__NO_CHANNEL: 'No channels',
  PLACE_HOLDER__WRONG: 'Something went wrong',
  PLACE_HOLDER__RETRY_TO_CONNECT: 'Retry',
  PLACE_HOLDER__NO_MESSAGES: 'No messages',
  NO_TITLE: 'No title',
  NO_NAME: '(No name)',
  NO_MEMBERS: '(No members)',
  TOOLTIP__AND_YOU: ', and you',
  TOOLTIP__YOU: 'you',
  TOOLTIP__UNKNOWN_USER: '(no name)',
  UNKNOWN__UNKNOWN_MESSAGE_TYPE: '(Unknown message type)',
  UNKNOWN__CANNOT_READ_MESSAGE: 'Cannot read this message.',
  MESSAGE_EDITED: '(edited)',
  MESSAGE_MENU__COPY: 'Copy',
  MESSAGE_MENU__REPLY: 'Reply',
  MESSAGE_MENU__EDIT: 'Edit',
  MESSAGE_MENU__RESEND: 'Resend',
  MESSAGE_MENU__DELETE: 'Delete',
  SEARCH: 'Search',
  SEARCH_IN_CHANNEL: 'Search in channel',
  SEARCH_IN: 'Search in',
  SEARCHING: 'Searching for messages...',
  NO_SEARCHED_MESSAGE: 'No results found.',
  QUOTE_MESSAGE_INPUT__REPLY_TO: 'Reply to',
  QUOTE_MESSAGE_INPUT__FILE_TYPE_IMAGE: 'Photo',
  QUOTE_MESSAGE_INPUT__FILE_TYPE_GIF: 'GIF',
  QUOTE_MESSAGE_INPUT__FILE_TYPE__VIDEO: 'Video',
  QUOTED_MESSAGE__REPLIED_TO: 'replied to',
  QUOTED_MESSAGE__CURRENT_USER: 'You',
  CONTEXT_MENU_DROPDOWN__COPY: 'Copy',
  CONTEXT_MENU_DROPDOWN__EDIT: 'Edit',
  CONTEXT_MENU_DROPDOWN__RESEND: 'Resend',
  CONTEXT_MENU_DROPDOWN__DELETE: 'Delete',
  MENTION_NAME__NO_NAME: '(No name)',
  MENTION_COUNT__OVER_LIMIT: 'You can mention up to %d times per message.',
}

export type EnglishTranslation = typeof en
export type EnglishChatTranslation = typeof chatEn

export { en, chatEn }
